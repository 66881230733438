<template>
    <marketing-layout>
        <marketing-hero>
            <h1 class="fw-bold heading-2 text-responsive mb-3">
                {{ $t('pages.marketing.reviews.title') }}
            </h1>
            <h2 class="fw-light heading-5 text-responsive mb-4">
                {{ $t('pages.marketing.reviews.subTitle') }}
            </h2>
            <a
                href="/"
                class="btn btn-primary"
            > Check Your Offer </a>
        </marketing-hero>

        <section-block
            class="pt-0"
            v-show="loadedTrustPilot"
        >
            <div class="container">
                <!-- TrustBox widget - Grid -->
                <div
                    ref="tpgrid"
                    class="trustpilot-widget"
                    data-locale="en-US"
                    data-template-id="539adbd6dec7e10e686debee"
                    data-businessunit-id="60db6c251ceb430001e0cfff"
                    :data-style-height="iFrameHeight"
                    data-style-width="100%"
                    data-theme="light"
                    data-tags="SelectedReview"
                    data-review-languages="en"
                >
                    <a
                        href="https://www.trustpilot.com/review/aven.com"
                        target="_blank"
                        rel="noopener"
                    >Trustpilot</a>
                </div>
                <!-- End TrustBox widget -->
            </div>
        </section-block>
    </marketing-layout>
</template>

<script>
    import Marketing from '../../layouts/Marketing'
    import SectionBlock from '@/components/SectionBlock'
    import MarketingHero from '@/components/MarketingHero'
    import { isSmallScreen, isMobile } from '@/mixins/deviceMixin'

    export default {
        name: 'Reviews',
        metaInfo: {
            title: 'Reviews from real customers',
            meta: [
                {
                    name: 'description',
                    vmid: 'description',
                    content: 'Our goal is to give you the most simple, secure, fast, and lowest cost access to your capital you can get. Read what our customers are saying about the Aven card.',
                },
            ],
        },
        mixins: [isSmallScreen, isMobile],
        computed: {
            iFrameHeight: function () {
                if (this.isSmallScreen()) {
                    return '4000px'
                } else {
                    return '1500px'
                }
            },
        },
        data() {
            return {
                loadedTrustPilot: false,
            }
        },
        mounted: async function () {
            this.$logEvent('view_reviews')
            const trustbox = this.$refs.tpgrid
            if (window.Trustpilot) {
                window.Trustpilot.loadFromElement(trustbox)
            }
            this.loadedTrustPilot = true
        },
        components: {
            'marketing-layout': Marketing,
            SectionBlock,
            MarketingHero,
        },
    }
</script>
